import React from "react"
import { graphql, PageProps } from "gatsby"

import { useAnalytics } from "../hooks"

import {
  BlogArticleList,
  FilterOption,
  Header,
  Layout,
  SEO,
  SignOffMailingList,
  SectionsHandler,
} from "../components"
import { pageType, blogArticleType, blogArticleTopicType } from "../types"

type Props = PageProps & {
  data: pageType & {
    allContentfulBlogArticles: {
      edges: Array<{ node: blogArticleType }>
    }
    allContentfulBlogArticleTopics: {
      edges: Array<{ node: blogArticleTopicType }>
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()

  const url = process.env.GATSBY_SITE_URL + "/blog/"

  analytics.track(["User", "Page View"], { page: "/blog/" })

  const [articles, setArticles] = React.useState(
    data.allContentfulBlogArticles.edges
  )
  const [filters, setFilters] = React.useState({
    selected: {
      name: "All topics",
      contentful_id: "all",
    },
    all: [
      {
        name: "All topics",
        contentful_id: "all",
      },
    ],
    visible: false,
  })

  React.useEffect(() => {
    const getAllArticleTopics = () => {
      let topics: Array<blogArticleTopicType> = [
        {
          name: "All topics",
          contentful_id: "all",
        },
      ]

      data.allContentfulBlogArticleTopics.edges.forEach(({ node }) => {
        topics.push({
          name: node.name,
          contentful_id: node.contentful_id,
        })
      })
      setFilters((filters) => ({ ...filters, all: topics }))
    }
    getAllArticleTopics()
  }, [])

  React.useEffect(() => {
    const filterArticles = () => {
      if (filters.selected.contentful_id === "all") {
        setArticles(data.allContentfulBlogArticles.edges)
      } else {
        const filteredArticles = data.allContentfulBlogArticles.edges.filter(
          ({ node }) =>
            node.topic.contentful_id === filters.selected.contentful_id
        )
        setArticles(filteredArticles)
        setFilters((filters) => ({ ...filters, visible: false }))
      }
    }

    filterArticles()
  }, [filters.selected])
  return (
    <Layout>
      <SEO
        title={data.contentfulPages.seo.title}
        description={data.contentfulPages.seo.description.description}
        image={data.contentfulPages.seo.socialImage?.file?.url}
        url={url}
      />
      <Header
        heading={data.contentfulPages.heading}
        headerIntroduction={data.contentfulPages.headerIntroduction}
        headerImage={data.contentfulPages.headerImage}
      />
      <main className="backgroundGreyLightSuper">
        <section className="wrapper wrapperSidebarLayout">
          <aside className={`wrapperSidebar${filters.visible ? " open" : ""}`}>
            <div className="sidebar">
              <span className="sidebarHeading">Filter by topic</span>
              {filters.all.map((topic) => (
                <FilterOption
                  key={topic.contentful_id}
                  value={topic.name}
                  onClick={() =>
                    setFilters((filters) => ({
                      ...filters,
                      selected: {
                        name: topic.name,
                        contentful_id: topic.contentful_id,
                      },
                    }))
                  }
                  filteredValue={filters.selected.name}
                />
              ))}
            </div>
            <button
              className="sidebarControl"
              onClick={() =>
                setFilters((filters) => ({
                  ...filters,
                  visible: !filters.visible,
                }))
              }
            >
              <span className="accessibleText">Show/hide filters</span>
            </button>
            <span className="fill"></span>
          </aside>
          <section className="filteredContent">
            <span className="filterCount">{`${articles.length} articles`}</span>
            {articles.map(({ node }) => (
              <BlogArticleList article={node} key={node.contentful_id} />
            ))}
          </section>
        </section>
      </main>
      {data.contentfulPages.sections && (
        <SectionsHandler sections={data.contentfulPages.sections} />
      )}
      <SignOffMailingList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query allBlogArticles {
    allContentfulBlogArticles(sort: { order: DESC, fields: posted }) {
      edges {
        node {
          contentful_id
          featuredImage {
            gatsbyImageData
            title
          }
          title
          preview {
            preview
          }
          topic {
            contentful_id
            name
          }
          posted
          slug
          author {
            image {
              gatsbyImageData
              title
            }
            name
            jobTitle
            contentful_id
          }
        }
      }
    }
    contentfulPages(page: { eq: "Blog" }) {
      contentful_id
      heading
      headerIntroduction {
        raw
      }
      headerImage {
        gatsbyImageData
        title
      }
      sections {
        ... on ContentfulPageSectionsCourseGroups {
          contentful_id
          name
          __typename
          courseGroups {
            ... on ContentfulCourseGroups {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                title
                gatsbyImageData
              }
              contentful_id
              slug
            }
            ... on ContentfulCourseTopics {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                gatsbyImageData
                title
              }
              contentful_id
              slug
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsFaQs {
          __typename
          contentful_id
          heading
          faQs {
            heading
            content {
              raw
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsHowItWorks {
          __typename
          contentful_id
          heading
          steps {
            contentful_id
            description {
              description
            }
            heading
            icon {
              file {
                url
              }
              gatsbyImageData
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStats {
          __typename
          contentful_id
          stats {
            name
            description {
              description
            }
            number
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsTextWithImage {
          __typename
          id
          contentful_id
          image {
            gatsbyImageData
            title
            url
          }
          video {
            url
            title
          }
          imageAlignment
          heading
          content {
            raw
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStrap {
          __typename
          id
          contentful_id
          buttonSupportingText
          description
          name
          buttonText
          buttonUrl
          backgroundColour
        }
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
    }
    allContentfulBlogArticleTopics(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          contentful_id
        }
      }
    }
  }
`
